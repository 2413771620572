
import {throwError as observableThrowError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import { HttpResponseStatus } from '../http-status-code.enum';

@Injectable({
  providedIn: 'root'
})
export class HttpResponseErrorHandlerService {

  RequestId!: string;
  RequestDate!: string;

  constructor(private router: Router) {}

  HandleResponseErrorWithNotification(error: any): Observable<any> {
    // TODO: Add notification service and notify of error
    console.log('Notification Error:', error);
    return observableThrowError(error);
  }

  HandleResponseError(error: any): Observable<any> {
    if (this.isResponse(error)) {

      // reset RequestId & RequestDate
      this.RequestId = '';
      this.RequestDate = '';

      const responseError = (error as any as Response);

      // Handle specific Response Errors
      switch (responseError.status) {
        case HttpResponseStatus.CODE_401_DENIED:
          const backUrl = location.href;

          console.log('WARNING: AuthGuard should be implemented for this route: ', backUrl);
         //  window.location.href = environment.loginPage + '?returnUrl=' + backUrl;
          break;

        case HttpResponseStatus.CODE_404_NOT_FOUND:
          this.router.navigateByUrl('/notFound');
          break;

        case HttpResponseStatus.CODE_400_BAD_REQUEST:
        case HttpResponseStatus.CODE_403_FORBIDDEN:
        case HttpResponseStatus.CODE_409_CONFLICT:
        default:
          console.log('INFO: Redirecting to generic Error-Page', responseError.status);
          
          // set RequestId & RequestDate from the response headers
          this.RequestId = error.headers.get('RequestId') ?? '';
          this.RequestDate = error.headers.get('RequestDate') ?? '';

          this.router.navigateByUrl('/error');
          break;
      }
    }

    return observableThrowError(error);
  }

  private isResponse(obj: any): obj is Response {
    // NOTE: This could be greatly enhanced
    return obj.status !== undefined && obj.url !== undefined;
  }
}
