import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { UserAuthorizationService } from '../../../security/user-authorization.service';
import moment from 'moment';

@Component({
    selector: 'app-default-header',
    templateUrl: './default-header.component.html',
    styleUrls: ['./default-header.component.scss'],
})
export class DefaultHeaderComponent implements OnInit {
    collapsed = false;
    username: string | undefined;
    greetingTextKey!: string;

    isLoggedIn?: boolean;

    isDevEnvironment = environment.production == false && environment.staging == false;
    isTestEnvironment = environment.staging == true;
    isProdEnvironment = environment.production == true;

    constructor(private authService: UserAuthorizationService) { }

    ngOnInit(): void {
        this.authService.isLoggedIn().then((x) => {
            if (x) {
                var user = this.authService.getUser();
                this.username = user.given_name;
            }
            this.isLoggedIn = x;
        });

       this.greetingTextKey = this.setGreetingTextKey();
    }

    setGreetingTextKey(): string {
        const currentHour = moment().hour();

        if (currentHour <= 11) return 'main.header.greetingMorning';
        if (currentHour >= 17) return 'main.header.greetingEvening';
        return 'main.header.greetingDay'
    }

    login() {
        this.authService.startAuthentication();
    }

    logout() {
        this.authService.logout();
    }
}
