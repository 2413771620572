import { Component, OnInit } from '@angular/core';
import { NewsModel } from '../models/news.model';
import { ActivatedRoute } from '@angular/router';
import { HeaderStateService } from '../../main/headers/services/header-state.service';
import { TranslocoService } from '@jsverse/transloco';
import { NewsService } from '../services/news.service';
import { switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';


@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrl: './news-details.component.scss'
})
export class NewsDetailsComponent implements OnInit {
  assignmentId!: number;
  newsModel: NewsModel | null = null;

  constructor(
    private newsService: NewsService,
    private route: ActivatedRoute,
    private headerStateService: HeaderStateService,
    private translocoService: TranslocoService
  ) {

  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        switchMap(params => {
          this.assignmentId = params['id'];
          if (this.assignmentId) {
            return this.newsService.getNewsDetails(this.assignmentId);
          }
          return EMPTY;
        })
      )
      .subscribe({
        next: (news) => {
          this.newsModel = news;
          this.headerStateService.setDetailHeader(
            this.translocoService.translate('news.title'),
            this.translocoService.translate('news.description'),
            '/home',
            ''
          );
        },
        error: (err) => {
          console.error('Error fetching news details:', err);
        }
      });
  }
}
