import { Component } from '@angular/core';
import { HeaderStateService } from '../../headers/services/header-state.service';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss'
})
export class FaqComponent {
  constructor(
    private headerStateService: HeaderStateService,
    private translocoService: TranslocoService
  ) {
    this.headerStateService.setDetailHeader(
      this.translocoService.translate('main.header.faqTitle')
    )
  };
}
