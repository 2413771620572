<div class="bg-white text-secondary custom-navbar h-100">
  <div class="container">
    <div class="d-flex">
      <div class="flex-even text-center">
        <a class="nav-link p-2" [routerLink]="'home'" routerLinkActive="active">
          <div><i class="fa-solid fa-house"></i></div>
          <div class="nav-link-text">
            {{ "main.navbar.home" | transloco }}
          </div>
        </a>
      </div>

      <div class="flex-even text-center">
        <a class="nav-link p-2" [routerLink]="'assignments'" routerLinkActive="active">
          <div><i class="fa-solid fa-calendar-days"></i></div>
          <div class="nav-link-text">
            {{ "main.navbar.assignments" | transloco }}
          </div>
        </a>
      </div>

      <div class="flex-even text-center">
        <a class="nav-link p-2" [routerLink]="'inbox'" routerLinkActive="active">
          <div><i class="fa-solid fa-envelope"></i></div>
          <div class="nav-link-text">
            {{ "main.navbar.inbox" | transloco }}
          </div>
        </a>
      </div>

      <div class="flex-even text-center">
        <a class="nav-link p-2" [routerLink]="'know-how'" routerLinkActive="active">
          <div><i class="fa-solid fa-file"></i></div>
          <div class="nav-link-text">
            {{ "main.navbar.knowHow" | transloco }}
          </div>
        </a>
      </div>

      <div class="flex-even text-center">
        <a class="nav-link p-2" [routerLink]="'profile'" routerLinkActive="active">
          <div><i class="fa-solid fa-user"></i></div>
          <div class="nav-link-text">
            {{ "main.navbar.profile" | transloco }}
          </div>
        </a>
      </div>
    </div>
  </div>
</div>