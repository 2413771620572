<ng-container *ngIf="!newsModel">
  <div class="text-center">
    <span><i class="fa-solid fa-spinner fa-spin"></i></span>
  </div>
</ng-container>

<ng-container *ngIf="newsModel">
<div class="d-flex flex-column gap-2">
    <div *ngFor="let model of newsModel">
      <a [routerLink]="['/news', model.id]" class="text-decoration-none">
        <div class="alert alert-primary text-white m-0">
          <!-- Row for Title and Date -->
          <div class="d-flex justify-content-between align-items-center mb-1">
            <h5 class="fw-bold mb-0">{{ model.title }}</h5>
            <div class="text-center text-nowrap">
              {{ model.publicationFrom | date: "dd.MM.yy" }}
            </div>
          </div>

          <!-- Row for News Text and Chevron Icon -->
          <div class="d-flex justify-content-between align-items-center">
            <p class="text-truncate small mb-0 flex-grow-1">
              {{ model.preview }}
            </p>
            <i class="fa fa-chevron-right ms-3"></i>
          </div>
        </div>
      </a>
    </div>
</div>
</ng-container>