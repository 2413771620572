import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UserAuthorizationService } from '../user-authorization.service';
import { environment } from '../../../environments/environment';

/**
 * Component is currently not working in ie11
 * use static oidc-login-redirect.html file instead
 */

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit {

  error?: string;

  constructor(private authService: UserAuthorizationService, private router: Router, private httpClient: HttpClient) {
  }

  ngOnInit() {
    this.authService.completeAuthentication().catch((error: string) => {
      this.errorHandling(error);
    });
  }

  errorHandling(error: string) {
    console.log('error on authentication ', error);
    const loginAttempts = localStorage.getItem('loginAttempts');
    console.log('loginAttempts: ', loginAttempts);
    if (loginAttempts == null) {
      localStorage.setItem('loginAttempts', '1');
      this.authService.redirectTo();
    }
    localStorage.removeItem('loginAttempts');
    this.showError(error);
  }

  showError(error: string) {
    const timestamp = new Date();
    const erroMsgDiv = document.getElementById('errorMsg');
    erroMsgDiv!.innerText = error;

    const timestampDiv = document.getElementById('timestamp');
    timestampDiv!.innerText = timestamp.toString();

    const errorDiv = document.getElementById('errorDiv');
    errorDiv!.style.display = 'block';

    this.sendError(error, timestamp);
  }

  sendError(error: string, timestamp: any) {
    const additionalInfo: any = {};

    for (let i = 0, len = localStorage.length; i < len; ++i) {
      const key = localStorage.key(i);
      additionalInfo[localStorage.key(i)!] = localStorage.getItem(key!);
    }

    const apiLog = {
      message: String(error),
      timestamp: timestamp,
      additionalInfo: additionalInfo,
      location: 'login.component.ts',
    };

    const url = environment.api + '/log/error';
    this.httpClient.post(url, apiLog).subscribe((res) => console.log(res));
  }
}
