import { UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { environment } from '../../../environments/environment';

export function getClientSettings(): UserManagerSettings {
  return {
    authority: environment.identity.authority,
    client_id: environment.identity.client_id,
    redirect_uri: environment.identity.redirect_uri,
    scope: environment.identity.scope,
    response_type: environment.identity.response_type,
    post_logout_redirect_uri: environment.identity.post_logout_redirect_uri,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    automaticSilentRenew: true,
    silent_redirect_uri: environment.identity.silent_redirect_uri,
    loadUserInfo: true
  };
}
