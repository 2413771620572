import { Injectable } from '@angular/core';
import { UserManager, User } from 'oidc-client';
import { getClientSettings } from './oidc/oidc-client-settings';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { ApplicationUser } from './application-user.model';

@Injectable({
  providedIn: 'root'
})
export class UserAuthorizationService {
  private manager: UserManager;
  private _oidcUser?: User;

  public userHasChanges$: Subject<void> = new ReplaySubject(1);

  setOidcUser(value?: User) {
    this._oidcUser = value;
    this.userHasChanges$.next();
  }

  constructor(private router: Router) {
    this.manager = new UserManager(getClientSettings());
    this.setUser();
    this.registerEvents();
  }

  registerEvents() {
    this.manager.events.addUserLoaded(() => {
      this.manager.getUser().then(user => this.setOidcUser(user ?? undefined))
    });
    this.manager.events.addSilentRenewError((errorResponse) => {
      // console.log('SilentRenewError:', errorResponse);
      if (errorResponse.message === 'login_required') {
        // TODO: Force manual login?
        // console.log('Silent-Renew did not complete successfully because a login is required.');
      }
    });
  }

  private setUser(): Promise<void | null> {
    return this.manager.getUser().then(user => {
      if (user && !user.expired) {
        this.setOidcUser(user);
        return;
      }
    });
  }

  startAuthentication(redirect_url?: string): Promise<void> {
    // write the desired location to the local storage
    // this will be retrieved after login and redirected
    // but just do so if there is not already an item in the local storage.
    //  so it's possible to set the redirect_url before logout (e.g. create new online membership).

    // if (redirect_url != null && !localStorage.getItem('redirect_url')) {
    //   localStorage.setItem('redirect_url', `${environment.baseHref}${redirect_url}`);
    // }

    // 27.01.2021 #155:
    // If for some reason, after clicking login and redirect_url is set, the user doesn't login again, the redirect_url doesn't get set again with the new desired url on the next login.
    // same problem after logout. After logout, the user gets redirect to '', then to /memberships and then login page. This sets the redirect_url to '/memberhip'.
    // If the user doesn't login again, the redirect_url ist set and not cleared. Therefor the user gets redirected to the memmbership page, regardless from where the next login occurs.
    // => set the redirect_url anyway, regardless if it is set already
    if (redirect_url != null) {
      localStorage.setItem('redirect_url', `${redirect_url}`);
    }

    this.manager.clearStaleState();
    return this.manager.signinRedirect();
  }

  completeAuthentication(): Promise<void> {
    return this.manager.signinRedirectCallback().then(() => {
      this.redirectTo();
      localStorage.removeItem('redirect_url');
      localStorage.removeItem('loginAttempts');
    });
  }

  redirectTo() {
    let redirectUrl = localStorage.getItem('redirect_url');
    if (redirectUrl == null) {
      redirectUrl = '/';
    }

    this.router.navigateByUrl(redirectUrl);
  }

  logout(): Promise<any> {
    return this.manager.signoutRedirect();
  }

  async isLoggedIn(): Promise<boolean> {
    if (this._oidcUser == undefined) {
      await this.setUser();
    }

    var isOidcUserLoggedIn = this._oidcUser != null && this._oidcUser?.access_token != null && !this._oidcUser?.expired;
    return Promise.resolve(isOidcUserLoggedIn);
  }

  getAccessToken(): string {
    return this._oidcUser ? this._oidcUser.access_token : '';
  }

  getIdToken(): string {
    return this._oidcUser ? this._oidcUser.id_token : '';
  }

  signoutRedirectCallback(): Promise<any> {
    return this.manager.signoutRedirectCallback();
  }

  getUser(): ApplicationUser {
    return this.getCurrentUser();
  }

  private getCurrentUser(): ApplicationUser {
    const userModel = new ApplicationUser();

    if (this._oidcUser == null) {
      return userModel;
    }

    userModel.nameid = this._oidcUser.profile['id'];
    userModel.unique_name = this._oidcUser.profile['name'];
    userModel.given_name = this._oidcUser.profile['given_name'];
    userModel.family_name = this._oidcUser.profile['family_name'];
    userModel.email = this._oidcUser.profile['email'];
    userModel.asvzId = +this._oidcUser.profile['http://schemas.staff.asvz.ch/2018/07/claims/asvzid'];
    userModel.roles = this.getRoleClaims(this._oidcUser.profile['role']);
    userModel.imId = +this._oidcUser.profile['http://schemas.staff.asvz.ch/2018/07/claims/imaddressid'];

    return userModel;
  }

  private getRoleClaims(profileClaim: string): Array<string> {
    // NOTE: The user must be actively logged in to recieve the role/claims
    if (!this.isLoggedIn()) {
      return [];
    }

    // NOTE: The role claim can be a single value or an array of values
    const roles = new Array<string>();
    const roleClaim = this._oidcUser?.profile['role'];
    if (roleClaim instanceof Array) {
      roleClaim.forEach(function (item) {
        roles.push(item);
      })
    } else {
      roles.push(roleClaim);
    }

    return roles;
  }
}
