import { NgModule } from '@angular/core'
import { SharedModule } from '../shared/shared.module'
import { RootComponent } from './root/root.component'
import { MainRoutingModule } from './main-routing.module'
import { NavbarComponent } from './navbar/navbar.component'
import { PageServerErrorComponent } from './errors/page-server-error/page-server-error.component'
import { PageNotFoundComponent } from './errors/page-not-found/page-not-found.component'
import { PageNoPermissionComponent } from './errors/page-no-permission/page-no-permission.component'
import { HomeModule } from '../home/home.module'
import { AssignmentModule } from '../assignments/assignment.module'
import { InboxModule } from '../inbox/inbox.module'
import { KnowHowModule } from '../know-how/know-how.module'
import { CommonModule } from '@angular/common'
import { FaqComponent } from './information/faq/faq.component'
import { HelpComponent } from './information/help/help.component'
import { ImprintComponent } from './information/imprint/imprint.component'
import { DefaultHeaderComponent } from './headers/default-header/default-header.component'
import { DetailHeaderComponent } from './headers/detail-header/detail-header.component'
import { TranslocoService } from '@jsverse/transloco'
import { MarkdownModule } from 'ngx-markdown'

@NgModule({
    declarations: [
        RootComponent,
        DefaultHeaderComponent,
        DetailHeaderComponent,
        NavbarComponent,

        // Errors
        PageNoPermissionComponent,
        PageNotFoundComponent,
        PageServerErrorComponent,

        // Information
        FaqComponent,
        HelpComponent,
        ImprintComponent,
    ],
    imports: [
        SharedModule,
        CommonModule,
        MainRoutingModule,
        HomeModule,
        AssignmentModule,
        InboxModule,
        KnowHowModule,
        MarkdownModule.forRoot()
    ],
    exports: [DefaultHeaderComponent, DetailHeaderComponent, NavbarComponent],
})
export class MainModule {
    constructor(translate: TranslocoService) {
        translate.setDefaultLang('de');
    }
}
