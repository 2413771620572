import { NgModule } from '@angular/core';
import { DatePipe } from "@angular/common";
import { HomeComponent } from './home.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { NewsModule } from '../news/news.module';

@NgModule({
    imports: [
        SharedModule,
        RouterModule,
        NewsModule
    ],
    declarations: [
        HomeComponent
    ],
    exports: [
    ],
    providers: [
        DatePipe
    ]
})
export class HomeModule { }
