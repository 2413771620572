import { Roles } from './roles.enum';

export class ApplicationUser {
  nameid?: string;
  unique_name?: string;
  given_name?: string;
  family_name?: string;
  email?: string;
  asvzId?: number;
  imId?: number;
  roles?: Array<string>;

  hasRole(role: Roles) {
    if (!this.roles) {
      return false;
    }
    return this.roles.indexOf(role.toString()) >= 0;
  }

  isAuthenticated() {
    return (this.nameid != null);
  }
}

