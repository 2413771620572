import { NgModule } from '@angular/core';
import { DatePipe } from "@angular/common";
import { SharedModule } from '../shared/shared.module';
import { KnowHowComponent } from './know-how.component';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        KnowHowComponent,
    ],
    exports: [
    ],
    providers: [
        DatePipe
    ]
})
export class KnowHowModule { }
