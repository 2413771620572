import { Component, OnInit } from '@angular/core';
import { NewsModel } from './models/news.model';
import { NewsService } from './services/news.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrl: './news.component.scss'
})
export class NewsComponent implements OnInit {
  newsModel!: NewsModel[];

  constructor(private newsService: NewsService) { }

  ngOnInit(): void {
    this.newsService.getNews().subscribe({
      next: (news) => {
        this.newsModel = this.sortDates(news);
      },
    })
  }

  private sortDates(news: NewsModel[]): NewsModel[] {
    return news.sort((a, b) => {
      const aFrom = new Date(a.publicationFrom);
      const bFrom = new Date(b.publicationFrom);
      const aTo = new Date(a.publicationTo);
      const bTo = new Date(b.publicationTo);

      // First, sort by publication start date
      if (aFrom.getTime() !== bFrom.getTime()) {
        return bFrom.getTime() - aFrom.getTime();
      }

      // If start dates are the same, sort by end date
      return bTo.getTime() - aTo.getTime();
    });
  }
}
