import { Component } from '@angular/core';
import { HeaderStateService } from '../../headers/services/header-state.service';
import { InformationService } from '../services/information-service';
import { ImprintModel } from '../model/imprint.model';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrl: './imprint.component.scss',
})
export class ImprintComponent {
  imprintModel?: ImprintModel;

  constructor(
    private headerStateService: HeaderStateService,
    private informationService: InformationService,
    private translocoService: TranslocoService
  ) {
    this.headerStateService.setDetailHeader(
      this.translocoService.translate('main.header.imprintTitle')
    )
  }

  ngOnInit(): void {
    this.loadMessage();
  }

  private loadMessage(): void {
    this.informationService.getImprint().subscribe({
      next: (value) => {
        this.imprintModel = value;
      },
      error: (err) => {
        console.error('Failed to load imprint message', err);
      }
    });
  }
}
