import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { NewsDetailsComponent } from './news-details/news-details.component';
import { MarkdownModule } from 'ngx-markdown';
import { NewsComponent } from './news.component';
import { DatePipe } from "@angular/common";
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        SharedModule,
        MarkdownModule.forRoot(),
        RouterModule
    ],
    declarations: [
        NewsDetailsComponent,
        NewsComponent
    ],
    exports: [NewsComponent],
    providers: [DatePipe]
})
export class NewsModule { }
