import { NgModule } from '@angular/core';
import { DatePipe } from "@angular/common";
import { SharedModule } from '../shared/shared.module';
import { InboxComponent } from './inbox.component';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        InboxComponent,
    ],
    exports: [
    ],
    providers: [
        DatePipe
    ]
})
export class InboxModule { }
