import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  collapsed = false;
  // username: string;
  // userId: string;
  username: string | undefined;
  isTestEnvironment = environment.staging == true;
  isDevEnvironment = environment.production == false && environment.staging == false;
  isProdEnvironment = environment.production == true;

  constructor() { }

  ngOnInit(): void {
  }

  logout() {
    // this.authService.logout();
  }

  changePassword() {
    // window.open(`${environment.identity}/Identity/Account/Manage/ChangePassword`, '_blank')
  }

  toggleNavigation() {
    this.collapsed = !this.collapsed;
  }

  openSpontaneoustours() {
    // window.location.href = environment.spontaneoustoursURL;
  }

  hideNavigation() {
    this.collapsed = false;
  }

  stopClick(event: any) {
    event.stopPropagation();
  }

}
