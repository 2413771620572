import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserAuthorizationService } from '../user-authorization.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  encapsulation: ViewEncapsulation.None
})
export class LogoutComponent implements OnInit {

  constructor(private authService: UserAuthorizationService) {
    this.authService.logout();
  }

  ngOnInit() {
  }

}
