<ng-container *ngIf="newsModel">
  <div class="d-flex flex-column gap-4">
    <div class="card bg-white border border-rounded shadow">
      <div class="card-header p-4">
        <div class="d-flex flex-column">
          <h3 class="fw-bold mb-0">{{ newsModel.title }}</h3>
          <p class="mb-0">{{ newsModel.publicationFrom | date: "dd.MM.yyyy" }}</p>
        </div>
      </div>
    </div>
    <markdown class="variable-binding" [data]="newsModel.content"></markdown>
  </div>
</ng-container>
