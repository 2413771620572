<div class="header bg-white text-dark text-center p-3 fixed-top">
    <div class="container">
        <div class="d-flex align-items-center">
            <div class="flex-even-1 text-start">
                <button *ngIf="headerStateService.returnUrl" class="btn btn-link m-0 p-0"
                    (click)="headerStateService.navigateBack()">
                    <i class="fa-solid fa-angle-left text-dark"></i>
                </button>
            </div>
            <div class="flex-even-2">
                <div class=" d-flex flex-column p-0 m-0 text-center header-text gap-2">
                    <b>{{headerStateService.pageTitle| async}}</b>
                    <p *ngIf="(headerStateService.pageSubtitle | async) != ''" class="p-0 m-0">
                        {{headerStateService.pageSubtitle| async}}
                    </p>
                </div>
            </div>
            <div class="flex-even-1 text-end">
                <button *ngIf="(headerStateService.actionIcon| async) != ''" class="btn btn-link text-dark m-0 p-0 "
                    (click)="actionButtonClicked()">
                    <span class="position-relative">
                        <i [ngClass]="headerStateService.actionIcon | async"></i>
                        <span class="activ-status-indicator position-absolute top-0 start-50 p-1 ms-1 rounded-circle"
                            *ngIf="headerStateService.isActiveStatusIndicatorDisplayed | async">
                        </span>
                    </span>

                </button>
            </div>
        </div>
    </div>
</div>