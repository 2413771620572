<div class="container">
  <div class="page-header">
    <h1>404</h1>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div style="margin-bottom: 40px;">
        <h2><strong>Die gesuchte Website kann nicht gefunden werden.</strong></h2>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <p>Bitte entschuldige, wir können die Seite daher nicht anzeigen. Dies kann verschiedene Gründe haben:</p>
      <ul>
        <li>Möglicherweise wurde die URL der Website nicht korrekt eingegeben.</li>
        <li>Vielleicht wurde die Website, die du suchst, verschoben, aktualisiert oder gelöscht.</li>
        <li>Eventuell mussten wir den Inhalt dieser URL löschen.</li>
        <li>Obwohl wir im ASVZ über 130 Sportarten anbieten, suchst du tatsächlich nach einer Sportart, die nicht zu
          unserem Angebot gehört.
        </li>
      </ul>
      <br/>

      <p>Du hast nun folgende Möglichkeiten:</p>
      <ul>
        <li>Vergewissere dich, dass die URL keine Fehler enthält.</li>
        <li>Versuche, über die Startseite auf die Seite zu gelangen, die du suchst.</li>
        <!--<li>Schreibe uns eine E-Mail an <a href="mailto:info@asvz.ch">info@asvz.ch</a> und schildere dein Problem
          unter
          Angabe des von dir verwendeten Browsers (inkl. Browserversion).
        </li>-->
      </ul>
    </div>
    <div class="col-md-3">
      <img src="assets/img/error/404.jpg" class="img-rounded">
    </div>
  </div>
</div>
