import { Component } from '@angular/core';
import { HeaderStateService } from '../../headers/services/header-state.service';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrl: './help.component.scss'
})
export class HelpComponent {
  constructor(
    private headerStateService: HeaderStateService,
    private translocoService: TranslocoService
  ) {
    this.headerStateService.setDetailHeader(
      this.translocoService.translate('main.header.helpTitle')
    );
  }
}
