import { Component, OnInit } from '@angular/core';
import { HeaderStateService } from '../headers/services/header-state.service';
import { BehaviorSubject } from 'rxjs';
import { UserAuthorizationService } from '../../security/user-authorization.service';

@Component({
    selector: 'app-root',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {

    constructor(
        public headerStateService: HeaderStateService
    ) { }

    ngOnInit(): void {

    }
}
